<script setup lang="ts">
const props: any = defineProps({
  modelValue: Boolean,
  title: {
    type: String,
    default: 'Title',
    required: true,
  },
  descriptionClass: {
    type: String,
    default: '',
    required: false,
  },
  disableClose: {
    type: Boolean,
    default: false,
  },
  onClose: Function,
  onClickPrimary: Function,
  onClickSecondary: Function,
  primaryButtonText: String,
  secondaryButtonText: String,
  disablePrimaryButton: Boolean,
  disableSecondaryButton: Boolean,
  width: {
    type: Number,
    default: 330,
  },
});

defineEmits(['update:modelValue']);
const model = ref(props.modelValue);
watch(() => props.modelValue, (value) => {
  model.value = value;
});
</script>

<template>
  <v-dialog
    v-model="model"
    transition="dialog-right-transition"
    class="side-modal-dialog"
    persistent
  >
    <div
      class="side-modal-container"
      :class="descriptionClass"
    >
      <div
        class="side-modal"
        :style="{ width: width + 'px' }"
      >
        <div class="header">
          <p class="text-h6 text-primary title">
            {{ title }}
          </p>
          <v-btn
            v-if="!disableClose"
            variant="text"
            icon="mdi-close"
            @click="onClose"
          />
        </div>
        <div class="body scroll-box">
          <div class="scroll-box-content h-100">
            <slot />
          </div>
        </div>
        <div
          v-if="onClickPrimary || onClickSecondary"
          class="footer"
        >
          <v-btn
            v-if="onClickPrimary"
            class="text-none text-subtitle-1 "
            color="primary"
            variant="flat"
            size="large"
            :disabled="disablePrimaryButton"
            @click="onClickPrimary"
          >
            {{ primaryButtonText }}
          </v-btn>
          <v-btn
            v-if="onClickSecondary"
            class="text-none text-subtitle-1 "
            color="primary"
            variant="outlined"
            size="large"
            :disabled="disableSecondaryButton"
            @click="onClickSecondary"
          >
            {{ secondaryButtonText }}
          </v-btn>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<style lang="scss">
.side-modal-dialog {
  .v-overlay__content {
    min-width: 100vw !important;
    min-height: 100vh !important;
  }

  .side-modal-container {
    height: 100vh;

  }
}

.side-modal {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #fff;
  position: fixed;
  top: 0;
  right: 0;

  .header {
    border-bottom: 1px solid #efefef;
  }

  .footer {
    border-top: 1px solid #efefef;
  }

  .header, .footer {
    min-height: 80px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    justify-content: space-between;
  }

  .title {
    font-weight: 700 !important;
  }

  .body {
    flex-grow: 1;
    padding: 8px 20px;
  }
}
</style>
